import React, { useEffect, useState } from 'react';
import '../css/Style.css';

function ReservationsList() {
  const [reservations, setReservations] = useState([]);
  const [infoVisible, setInfoVisible] = useState(true);

  const hoy = new Date().toISOString().split('T')[0];

  useEffect(() => {
    fetch('/api/reservas_usuario_pregrado', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        date: hoy,
        nombre: localStorage.getItem('nombre').split('"')[1],
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.length === 0) {
        setInfoVisible(false);
      } else {
        const formattedData = data.map(reserva => {
          let fecha = reserva[0].split(' ')[1] + ' ' + reserva[0].split(' ')[2] + ' ' + reserva[0].split(' ')[3];
          let formattedDate = new Date(fecha).toLocaleDateString();
          formattedDate = formattedDate.split('/').reverse().join('-');
          return { date: formattedDate, spot: reserva[1], time: reserva[2], id: reserva[3] };
        });
        setReservations(formattedData);
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }, []);

  const handleCancelReservation = (idReserva) => {
    const confirmacion = window.confirm('¿Estás seguro/a de que deseas cancelar esta reserva?');
    if (confirmacion) {
      fetch(`/api/eliminar_reserva_pregrado/${idReserva}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          alert(data.message);
          // Actualizar la lista de reservas eliminando la cancelada
          setReservations(prevReservations => prevReservations.filter(reserva => reserva.id !== idReserva));
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  };

  return (
    <div>
      <h2 className='algo'>Tus próximas reservas:</h2>
      {infoVisible ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Puesto</th>
                <th>Horario</th>
                <th>Cancelar</th>
              </tr>
            </thead>
            <tbody>
              {reservations.map((reserva, index) => (
                <tr key={index}>
                  <td>{reserva.date}</td>
                  <td>{reserva.spot}</td>
                  <td>{reserva.time}</td>
                  <td>
                    <button onClick={() => handleCancelReservation(reserva.id)}>
                      Cancelar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No hay reservas futuras.</p>
      )}
    </div>
  );
}

export default ReservationsList;
