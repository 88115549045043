import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Style.css';

function SeatSelection({ selectedDate, selectedTime }) {
  const [asientoSeleccionado, setAsientoSeleccionado] = useState(null);
  const [asientosReservados, setAsientosReservados] = useState([]);

  useEffect(() => {
    if (selectedDate && selectedTime) {
      fetchReservations(selectedDate, selectedTime);
    }
  }, [selectedDate, selectedTime]);

  const fetchReservations = (fecha, horario) => {
    setAsientoSeleccionado(null);
    fetch('/api/reservationss', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fecha: fecha,
        horario: horario,
      }),
    })
    .then(response => response.json())
    .then(reservas => {
      setAsientosReservados(reservas);

      // Remover la clase 'reservado' de todos los asientos
      document.querySelectorAll('.asiento').forEach(asiento => {
        if (asiento.id !== 'muestra'){
        asiento.classList.remove('reservado');
        asiento.style.pointerEvents = 'auto'; // Hacer que el asiento sea clickeable por defecto
        }
      });

      reservas.forEach(puesto => {
        let asiento = document.getElementById(puesto.toString());
        if (asiento) {
          asiento.classList.add('reservado');
          asiento.style.pointerEvents = 'none'; // Hacer que el asiento no sea clickeable
        }
      });
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const seleccionarAsiento = (asientoId) => {
    if (asientoSeleccionado === asientoId) {
      setAsientoSeleccionado(null);
    } else {
      setAsientoSeleccionado(asientoId);
    }
  };

  const reservarAsiento = () => {
    if (!asientoSeleccionado || !selectedDate || !selectedTime) {
      alert('Por favor selecciona un asiento, una fecha y horario válidos');
      return;
    }

    fetch('/api/reservations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fecha_agendamiento: selectedDate,
        horario: selectedTime,
        puesto: asientoSeleccionado,
        usuario: localStorage.getItem('usuario').split('"')[1],
      }),
    })
    .then(response => {
      if (response.ok) {
        alert('Reserva realizada con éxito');
        window.location.reload();
      } else {
        response.json().then(data => {
          if (data.message) {
            alert(data.message);
          }
        });
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  const renderAsiento = (id) => {
    const isSeleccionado = asientoSeleccionado === id.toString();
    return (
      <div
        key={id}
        className={`asiento ${isSeleccionado ? 'seleccionado' : ''}`}
        id={id}
        onClick={() => seleccionarAsiento(id.toString())}
      >
        <span className="numero-asiento">{id}</span>
      </div>
    );
  };

  return (
    <div>
      <div>
        <ul className="showcase">
          <li>
            <div className="asiento"></div>
            <small>Disponible</small>
          </li>
          <li>
            <div className="asiento seleccionado"></div>
            <small>Seleccionado</small>
          </li>
          <li>
            <div className="asiento reservado" id='muestra'></div>
            <small>Reservado</small>
          </li>
        </ul>

        <div className="container">
          <div className="row">
            {renderAsiento(10)}
            {renderAsiento(9)}
          </div>
          <div className="row">
            {renderAsiento(8)}
            {renderAsiento(7)}
          </div>

          <div className="separacion"></div>

          <div className="row">
            {renderAsiento(6)}
            {renderAsiento(5)}
          </div>

          <div className="row">
            {renderAsiento(4)}
            {renderAsiento(3)}
          </div>

          <div className="separacion"></div>

          <div className="row">
            {renderAsiento(2)}
            {renderAsiento(1)}
          </div>

          <div className="separacion"></div>

          <button className="boton" id="reservar" onClick={reservarAsiento}>Reservar</button>
        </div>
      </div>
    </div>
  );
}

export default SeatSelection;
