import React, { useState, useEffect } from 'react';
import DateSelector from '../components/DateSelectorPregrado';
import TimeSelector from '../components/TimeSelectorPregrado';
import SeatSelection from '../components/SeatSelection';
import ReservationsList from '../components/ReservationsListPregrado';
import '../css/Style.css';

function Reserva() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [seats, setSeats] = useState(Array(10).fill({ isReserved: false, id: null }).map((seat, index) => ({ ...seat, id: (index + 1).toString() })));
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [nombre, setNombre] = useState('');

  useEffect(() => {
    var nombre = localStorage.getItem('nombre');
    nombre = JSON.parse(nombre);
    setNombre(nombre.split(' ')[0]);
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleSeatSelect = (id) => {
    setSelectedSeat(selectedSeat === id ? null : id);
  };

  return (
    <div className="contenedor-principal">
      <div className="asientos-y-reservas">
        <h2>Hola {nombre}</h2>
        <DateSelector onDateChange={handleDateChange} />
        <TimeSelector onTimeChange={handleTimeChange} />
        <SeatSelection
          seats={seats}
          selectedSeat={selectedSeat}
          onSeatSelect={handleSeatSelect}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
        />
      </div>
      <div className="cancelar-reservas">
        <ReservationsList />
      </div>
    </div>
  );
}

export default Reserva;
