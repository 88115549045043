import React, { useEffect, useState } from 'react';

function ReservaHoy() {
  const [reservasHoy, setReservasHoy] = useState([]);

  useEffect(() => {
    fetch('/api/asientos_hoy_pregrado', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ date: new Date().toLocaleDateString().split('/').reverse().join('-') })
    })
    .then(response => response.json())
    .then(data => setReservasHoy(data));
  }, []);

  return (
    <div>
        <table>
            <thead>
                <tr>
                <th>Nombre</th>
                <th>Asiento</th>
                <th>Horario</th>
                </tr>
            </thead>
            <tbody>
                {reservasHoy.map(reserva => (
                <tr key={reserva[4]}>
                    <td>{reserva[0]}</td>
                    <td>{reserva[1]}</td>
                    <td>{reserva[2]}</td>
                </tr>
                ))}
            </tbody>
        </table>
    </div>
  );
}

export default ReservaHoy;
