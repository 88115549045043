import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import '../css/Dashboard.css';

function Asientos() {
  const [reservas, setReservas] = useState([]);

  useEffect(() => {
    const fetchReservas = () => {
      const today = format(new Date(), 'yyyy-MM-dd');
      fetch('/api/calendario-asientos-pregrado', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date: today,
          token: localStorage.getItem('admin_token')
        }),
      })
        .then(response => response.json())
        .then(data => {
          setReservas(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    fetchReservas();
  }, []);

  const handleEliminarReserva = (idReserva) => {
    const confirmacion = window.confirm('¿Estás seguro/a de que deseas eliminar esta reserva?');
    if (!confirmacion) return;
    fetch(`/api/eliminar_reserva_pregrado/${idReserva}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setReservas(reservas.filter(reserva => reserva[4] !== idReserva));
        } else {
          console.error('Error al eliminar la reserva');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Asiento</th>
              <th>Fecha</th>
              <th>Horario</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {reservas.map(reserva => (
              <tr key={reserva[4]}>
                <td>{reserva[0]}</td>
                <td>{reserva[1]}</td>
                <td>{new Date(reserva[2]).toLocaleDateString()}</td>
                <td>{reserva[3]}</td>
                <td>
                  <button onClick={() => handleEliminarReserva(reserva[4])}>
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Asientos;

