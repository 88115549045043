import '../css/Dashboard.css';
import React, { useEffect, useState } from 'react';

function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [puestosVitalicios, setPuestosVitalicios] = useState({});
  const [nuevoPuesto, setNuevoPuesto] = useState({});
  const [puestosDisponibles, setPuestosDisponibles] = useState([]);
  const [actualizar, setActualizar] = useState(false);

  useEffect(() => {
    // Cargar usuarios
    fetch('/api/usuarios')
      .then(response => response.json())
      .then(data => setUsuarios(data));

    // Cargar puestos vitalicios
    fetch('/api/puestos_vitalicios')
      .then(response => response.json())
      .then(data => {
        const vitalicios = {};
        data.forEach(([puesto, nombre]) => {
          if (!vitalicios[nombre]) {
            vitalicios[nombre] = [];
          }
          vitalicios[nombre].push(puesto);
        });
        setPuestosVitalicios(vitalicios);
      });

    fetch('/api/vitalicios_disponibles')
      .then(response => response.json())
      .then(data => setPuestosDisponibles(data));
  }, [actualizar]);

  const handleEliminarUsuario = (usuario) => {
    if (window.confirm('¿Seguro que quieres eliminar este usuario?')) {
      fetch('/api/eliminar_usuario', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario: usuario[1] }),
      })
        .then(response => response.json())
        .then(() => 
            setUsuarios(usuarios.filter(u => u[1] !== usuario[1])));
    }
  };

  const handleAsignarPuestoVitalicio = (usuario, puesto) => {
    fetch('/api/puestos_vitalicios', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ usuario: usuario[1], puesto: puesto }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          setActualizar(!actualizar);
        }
      });
  };

  const handleEliminarPuestoVitalicio = (usuario, puesto) => {
    if (window.confirm('¿Seguro que quieres eliminar el puesto vitalicio de este usuario?')) {
      fetch('/api/eliminar_vitalicio', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ puesto: puesto }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            const updatedVitalicios = { ...puestosVitalicios };
            updatedVitalicios[usuario[0]] = updatedVitalicios[usuario[0]].filter(p => p !== puesto);
            setPuestosVitalicios(updatedVitalicios);
            setActualizar(!actualizar);
          }
        });
    }
  };

  return (
    <div className="usuarios-container">
      <h2>Usuarios</h2>
      <div className='table'>
      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Usuario</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map(usuario => (
            <tr key={usuario[1]}>
              <td>{usuario[0]}</td>
              <td>{usuario[1]}</td>
              <td>
                <button onClick={() => handleEliminarUsuario(usuario)}>Eliminar</button>
                {puestosVitalicios[usuario[0]] && puestosVitalicios[usuario[0]].length > 0 ? (
                  puestosVitalicios[usuario[0]].map((puesto) => (
                    <div key={puesto}>
                      <span>Puesto Vitalicio: {puesto}</span>
                      <button onClick={() => handleEliminarPuestoVitalicio(usuario, puesto)}>
                        Eliminar Puesto Vitalicio
                      </button>
                    </div>
                  ))
                ) : (
                <div>
                    <select
                      onChange={(e) => {
                        const { value } = e.target;
                        setNuevoPuesto({ ...nuevoPuesto, [usuario[1]]: value });
                      }}
                    >
                      <option value="">Seleccionar puesto</option>
                      {puestosDisponibles.map(puesto => (
                        <option key={puesto} value={puesto}>{puesto}</option>
                      ))}
                    </select>
                    <button onClick={() => handleAsignarPuestoVitalicio(usuario, nuevoPuesto[usuario[1]])}>Asignar Puesto Vitalicio</button>
                </div>
                )}
                </td>
            </tr>
             ))}
        </tbody>
    </table>
    </div>
    </div>
);}
                
export default Usuarios;
