import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import '../css/Style.css'

const DateSelector = ({ onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const today = new Date();
  const nextTwoWeeks = new Date(today);
  nextTwoWeeks.setDate(today.getDate() + 14);

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd'); // Formatea la fecha a YYYY-MM-DD
    setSelectedDate(date); // Guarda la fecha seleccionada en el estado
    onDateChange(formattedDate); // Pasa la fecha formateada al componente padre
  };

  // Función para deshabilitar los fines de semana
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // 0 es domingo y 6 es sábado
  };

  const handleInputKeyDown = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <DatePicker
        className="date-picker"
        selected={selectedDate}
        onChange={handleDateChange}
        minDate={today}
        maxDate={nextTwoWeeks}
        filterDate={isWeekday}
        dateFormat="yyyy-MM-dd"
        placeholderText="Elige una fecha"
        onKeyDown={handleInputKeyDown}
        customInput={
          <button className="date-button">
            {selectedDate ? format(selectedDate, 'yyyy-MM-dd') : 'Elige una fecha'}
          </button>
        }
      />
    </div>
  );
};

export default DateSelector;
