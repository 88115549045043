import React from 'react';

function FormUsuario({ title, url }) {
    
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === 'Usuario creado correctamente') {
          window.location.reload();
        } else {
          alert('Error al crear usuario');
        }
      })
      .catch(error => {
        console.error('Error al crear usuario:', error);
        alert('Error al crear usuario');
      });
  };

  return (
    <div className='form'>
      <h3>{title}</h3>
      <form onSubmit={handleSubmit} className='form-container'>
        <input type="text" name="nombre" placeholder="Nombre Completo" required />
        <input type="text" name="usuario" placeholder="Usuario" required />
        <input type="submit" value={title.split(' ')[0]} />
      </form>
    </div>
  );
}

export default FormUsuario;