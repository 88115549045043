import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

const AdminProtected = ({ children }) => {

    const token = localStorage.getItem('admin_token');

    if (!token) {
      return <Navigate to="/" />;
    }
    return children;
  };

export default AdminProtected;