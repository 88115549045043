import React, { useState, useEffect } from 'react';
import Asientos from '../components/Asientos';
import Usuarios from '../components/Usuarios';
import FormUsuario from '../components/FormUsuarioPregrado';
import ReservaHoy from '../components/ReservaHoyPregrado';
import '../css/Dashboard.css';

function Dashboard() {
  return (
    <div className="contenedor-principal">
      <div className="container">
        <h2>Reservas futuras</h2>
        <Asientos />
        <h2>Reservas de Hoy</h2>
        <ReservaHoy />
      </div>
      <div className="container">
        <Usuarios />
        <FormUsuario title="Crear Usuario" url="/api/create_user" />
      </div>
    </div>
  );
}

export default Dashboard;
