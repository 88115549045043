import Login from './views/Login';
import Reserva from './views/Reserva';
import ProtectedRoute from './components/ProtectedRoute';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './views/Dashboard';
import Admin from './views/Admin';
import AdminProtected from './components/AdminProtected';
import AdminPregrado from './views/AdminPregrado';
import LoginPregrado from './views/LoginPregrado';
import DashboardPregrado from './views/DashboardPregrado';
import ReservaPregrado from './views/ReservaPregrado';
import Index from './views/Index';

function App() {

  return (
    <div>
      
      <HashRouter>
        <Routes>
          <Route path="/" element={< Index />} />
          <Route path="/logincowork" element={< LoginPregrado />} />
          <Route path="/admincowork" element={< AdminPregrado />} />
          <Route path="/reservacowork" element={ <ProtectedRoute> <ReservaPregrado /> </ProtectedRoute> } />
          <Route path="/dashboardcowork" element={ <AdminProtected> <DashboardPregrado /> </AdminProtected> } />

          <Route path="/loginpostgrado" element={< Login />} />
          <Route path="/adminpostgrado" element={< Admin />} />
          <Route path="/reservapostgrado" element={ <ProtectedRoute> <Reserva /> </ProtectedRoute> } />
          <Route path="/dashboardpostgrado" element={ <AdminProtected> <Dashboard /> </AdminProtected> } />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
