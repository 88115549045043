import React from 'react'
import { useNavigate } from 'react-router-dom'

function Index() {

    const handleCowork = () => {
        navigate('/logincowork');
    }

    const handlePostgrado = () => {
        navigate('/loginpostgrado');
    }

    const navigate = useNavigate()
  return (
    <div className='login-container'>
      <h1>Reservas</h1>
      <p>Bienvenido/a a la plataforma de reservas para espacios de trabajo del departamento de Informática UdeC</p>
      <p>Porfavor, selecciona que espacio quieres reservar</p>
      <button style={{ marginBottom: '10px' }} onClick={ handleCowork }>Sala Cowork</button>
      <button onClick={ handlePostgrado }>Sala Postgrado</button>
    </div>
  )
}

export default Index
