import React, { useState, useEffect } from 'react';
import AsientosPregrado from '../components/AsientosPregrado';
import ReservaHoyPregrado from '../components/ReservaHoyPregrado';
import '../css/Dashboard.css';

function DashboardPregrado() {
  return (
    <div className="contenedor-principal">
      <div className="container">
        <h2>Reservas de Hoy</h2>
        <ReservaHoyPregrado />
        <h2>Reservas futuras</h2>
        <AsientosPregrado />
      </div>
    </div>
  );
}

export default DashboardPregrado;
