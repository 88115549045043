import React, { useState } from 'react';

const TimeSelector = ({ onTimeChange }) => {
  const [selectedTime, setSelectedTime] = useState('');

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
    onTimeChange(time);
  };

  return (
    <div>
      <div className="time-selector-container">
        <label className={`time-checkbox ${selectedTime === 'Mañana' ? 'selected' : ''}`}>
          <input
            type="checkbox"
            checked={selectedTime === 'Mañana'}
            onChange={() => handleTimeSelect('Mañana')}
          />
          Mañana (8:00 - 14:00)
        </label>
        <label className={`time-checkbox ${selectedTime === 'Tarde' ? 'selected' : ''}`}>
          <input
            type="checkbox"
            checked={selectedTime === 'Tarde'}
            onChange={() => handleTimeSelect('Tarde')}
          />
          Tarde (14:00 - 20:00)
        </label>
      </div>
    </div>
  );
};

export default TimeSelector;
