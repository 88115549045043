/* global google */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import '../css/Login.css';

function Login() {
    
    const [user, setUser] = useState('');
    const navigate = useNavigate();

    function handleCallbackResponse(response) {
        const userObject = jwtDecode(response.credential);
        setUser(userObject);
    }

    function handleSignin(){
        var username = user.email.split('@')[0];
        fetch('/api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
            }),
        })
        .then(response => {
            if (response.status === 200) {
                localStorage.setItem('nombre', JSON.stringify(user.name));
                localStorage.setItem('usuario', JSON.stringify(user.email.split('@')[0]));
                navigate('/reserva');
            } else {
                alert('Usuario no encontrado');
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
  
    useEffect(() => {
        google.accounts.id.initialize({
            client_id: "741266038975-j541dnlu0psioes5cor64qar1ue45g7c.apps.googleusercontent.com",
            callback: handleCallbackResponse
        });
  
        google.accounts.id.renderButton(
            document.getElementById("signin-button"),
            { theme: "outline", size: "large" }
        )
    }, []);


  return (
    <div className='login-container'>
        { localStorage.clear() }
        <h1>Reservas Postgrado</h1>
        <p>Por favor, ingresa con tu cuenta inf.udec.cl.</p>
        <p>Si tienes problemas para ingresar, por favor contacta a mauricio@inf.udec.cl</p>
        <div id="signin-button"></div>

        { user && 
            handleSignin()
        }
    </div>
  )
}

export default Login
